<template>
    <div> <el-card>
            <el-page-header content="更新公告" @back="handleBack()" title="公告管理" />
            <el-form ref="noticeFormRef" :model="noticeForm" :rules="noticeFormRules" label-width="80px" class="admin-Form">
                <el-form-item label="中文标题" prop="title">
                    <el-input v-model="noticeForm.title" style="width: 40%;" placeholder="请输入中文内容" />
                    <span style="margin-left:10%">英文标题：</span>
                    <el-input v-model="noticeForm.titleen" style="width: 40%;" placeholder="请输入英文内容"/>
                </el-form-item>
                <el-form-item label="中文内容" prop="content">
                    <editor   ref="editorRefCN" />
                </el-form-item>
                <el-form-item label="英文内容" prop="content">
                    <editor   ref="editorRefEN" />
                </el-form-item>
                <el-form-item label="是否发布" prop="isPublish">

                    <el-radio-group v-model="noticeForm.isPublish">
                        <el-radio :label="1">是</el-radio>
                        <el-radio :label="2">否</el-radio>
                    </el-radio-group>
                </el-form-item>
                <!-- <el-form-item>
                    <el-button type="primary" @click="submitForm()">更新公告</el-button>
                </el-form-item> -->
                <div style="text-align: center;"> <el-button  type="primary" @click="submitForm()">更新公告</el-button></div>
            </el-form>
        </el-card>
    </div>
</template>
<script setup>
import { ref, reactive, onMounted } from 'vue'
import editor from '@/components/editor/Editor.vue'
import { ElMessage } from 'element-plus';
import axios from 'axios';
import { useRouter, useRoute } from 'vue-router'
const router = useRouter()
const route = useRoute()
const noticeFormRef = ref()
const editorRefCN=ref()
const editorRefEN=ref()
const noticeForm = reactive({
    id:0,
    title: "",
    content: "",
    titleen:"",
    contenten: "",
    isPublish: 2,//默认不发布
})
const handleBack = () => {
    router.back()
}
//加载是渲染数据
onMounted(async () => {
    const res = await axios.get(`/adminapi/notice/list/${route.params.id}`)
    Object.assign(noticeForm, res.data.data)
    editorRefCN.value.valueHtml=noticeForm.content
    editorRefEN.value.valueHtml=noticeForm.contenten
})
const noticeFormRules = reactive({
    title: [
        {
            required: true, message: "请输入公告标题", trigger: "blur"
        }
    ], content: [
        {
            required: true, message: "请输入内容", trigger: "blur"
        }
    ], isPublish: [
        {
            required: true, message: "请选择状态", trigger: "blur"
        }
    ]
})
//每次editor 内容改变的回调
const handleChange = (data) => {
    noticeForm.content = data 
}
//更新公告
const submitForm = () => {
    noticeForm.content= editorRefCN.value.valueHtml    
    noticeForm.contenten= editorRefEN.value.valueHtml
    noticeFormRef.value.validate(async (valid) => {
        if (valid) {
            await axios.put("/adminapi/notice/list", noticeForm).then(res => {
                if (res.data.code == 200) {
                    router.back()
                    ElMessage.success(res.data.msg)
                } else {
                    ElMessage.error(res.data.msg)
                }
            })
        }
    })
}
</script>
<style lang="scss" scoped>
.admin-Form {
    margin-top: 20px;
}
</style>