import Home from '../views/home/Home.vue'
import Center from '../views/center/Center.vue'
import NoticeAdd from '../views/notice-manage/NoticeAdd.vue'
import NoticeList from '../views/notice-manage/NoticeList.vue'
import NoticeEdit from '../views/notice-manage/NoticeEdit.vue' 
import UserList from '../views/user-manage/UserList.vue'
import SystemAdd from '../views/system-manage/SystemAdd.vue'
import SystemList from '../views/system-manage/SystemList.vue'
import SystemSet from '../views/system-manage/SystemSet.vue'
import LogList from '../views/log-manage/LogList.vue'
import NotFound from '../views/notfound/NotFound.vue'
import InMoneyList from '../views/list-manage/InMoneyList.vue'
import PowerList from '../views/list-manage/PowerList.vue'
import RewardList from '../views/list-manage/RewardList.vue' 
import RemoveLpList from '../views/list-manage/RemoveLpList.vue'
import ExtractList from '../views/list-manage/ExtractList.vue' 
import ExchangeList from '../views/list-manage/ExchangeList.vue'
import OutUsdtList from '../views/list-manage/OutUsdtList.vue'
import IntroductionSet from '../views/set-manage/IntroductionSet.vue'
import RouleSet from '../views/set-manage/RouleSet.vue'
const routes=[
    {
        path: '/index',
        component: Home
      },{
        path: '/center',
        component: Center
      },{
        path: '/system-manage/addsystem',
        component: SystemAdd,
        requireAdmin:true
      }
      ,{
        path: '/system-manage/systemlist',
        component: SystemList,
        requireAdmin:true
      } ,{
        path: '/system-manage/systemset',
        component: SystemSet,
        requireAdmin:true
      },{
        path: '/notice-manage/addnotice',
        component: NoticeAdd
      }
      ,{
        path: '/notice-manage/noticelist',
        component: NoticeList
      }, 
      {
        path: '/notice-manage/editnotice/:id',
        component: NoticeEdit
      } 
      ,{
        path: '/user-manage/userlist',
        component: UserList
      } ,{
        path: '/',
        redirect: "/index"
      },{
        path: '/:pathMathch(.*)*',
        name:'NotFound',
        component: NotFound
      },{
        path: '/list-manage/inmoneylist',
        component: InMoneyList
      },{
        path: '/list-manage/powerlist',
        component: PowerList
      },{
        path: '/list-manage/rewardlist',
        component: RewardList
      },{
        path: '/list-manage/outusdtlist',
        component: OutUsdtList
      },
      {
        path: '/log-manage/loglist',
        component: LogList,
        requireAdmin:true

      },
      {
        path: '/list-manage/removelplist',
        component: RemoveLpList
      },
      {
        path: '/list-manage/extractlist',
        component: ExtractList
      },
      {
        path: '/list-manage/exchangelist',
        component: ExchangeList
      },
      {
        path: '/set-manage/introductionset',
        component: IntroductionSet
      }
      ,
      {
        path: '/set-manage/rouleset',
        component: RouleSet
      }
]

export default routes