<template>
    <div>
        <el-card>
            <el-page-header content="项目规则设置" icon="" title="项目设置" />
            <el-form ref="rouleFormRef" :model="rouleForm" :rules="rouleFormRules" label-width="100px" class="admin-Form">
                <div>中文内容</div>
                <Editor ref="editorRefCN" v-model="rouleForm.contentCN" />
                <div>英文内容</div>
                <Editor ref="editorRefEN" v-model="rouleForm.contentEN" />
                <div style="text-align: center;"> <el-button  type="primary" @click="submitForm()">修改</el-button></div>
            </el-form>
        </el-card>
    </div>
</template>
<script setup>
import Editor from '@/components/editor/Editor'
import { ref, reactive, onMounted } from 'vue'
import { ElMessage } from 'element-plus';
import { useRouter } from 'vue-router'
import axios from 'axios';
const router = useRouter()
const rouleFormRef = ref()
const rouleForm = reactive({
    id: 1,
    contentCN: 'sss',
    contentEN: '',
})
const editorRefCN = ref();
const editorRefEN = ref()
const rouleFormRules = reactive({
    contentCN: [
        {
            required: true, message: "中文内容不能为空", trigger: "blur"
        }
    ], contentEN: [
        {
            required: true, message: "英文内容不能为空", trigger: "blur"
        }
    ]
})


onMounted(() => {
    getTableData()
})
const getTableData = async () => {
    const res = await axios.get("/adminapi/roule/info")
    Object.assign(rouleForm, res.data.data)
    editorRefCN.value.valueHtml = rouleForm.contentCN
    editorRefEN.value.valueHtml = rouleForm.contentEN
}
 
//更新提交
const submitForm = async () => { 
    rouleForm.contentCN=editorRefCN.value.valueHtml
    rouleForm.contentEN=editorRefEN.value.valueHtml 
    rouleFormRef.value.validate(async (valid) => {
        if (valid) {
            await axios.post("/adminapi/roule/update", rouleForm).then(res => {
                if (res.data.code == 200) {
                    ElMessage.success(res.data.msg)
                } else {
                    ElMessage.error(res.data.msg)
                }
            })
        }
    })
}

</script>
<style lang="scss" scoped>
.admin-Form {
    margin-top: 20px;
}
</style>