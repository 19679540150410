<template>
  <div style="border: 1px solid #ccc">
    <Toolbar style="border-bottom: 1px solid #ccc" :editor="editorRef" :defaultConfig="toolbarConfig" :mode="mode" />
    <Editor style="height: 500px; overflow-y: hidden;" v-model="valueHtml" :defaultConfig="editorConfig" :mode="mode"
      @onCreated="handleCreated" />
  </div>
</template>
 
<script>
import '@wangeditor/editor/dist/css/style.css' // 引入 css

import { onBeforeUnmount, ref, shallowRef, onMounted } from 'vue'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'

export default {
  components: { Editor, Toolbar },
  setup() {
    // 编辑器实例，必须用 shallowRef
    const editorRef = shallowRef()
    // 内容 HTML
    const valueHtml = ref('<p>请输入内容</p>')
    // 模拟 ajax 异步获取内容
    onMounted(() => {
    })

    const toolbarConfig = {
      excludeKeys: [ 
        'group-video' // 排除菜单组，写菜单组 key 的值即可
    ]
    }
    const editorConfig = { MENU_CONF: {} }
    editorConfig.MENU_CONF['uploadImage'] = {
      // 上传图片的配置
      server: '/adminapi/uploade/uploadeimg',

      // form-data fieldName ，默认值 'wangeditor-uploaded-image'
      fieldName: 'file',

      // 单个文件的最大体积限制，默认为 2M
      maxFileSize: 1 * 1024 * 1024, // 1M

      // 最多可上传几个文件，默认为 100
      maxNumberOfFiles: 10,

      // 选择文件时的类型限制，默认为 ['image/*'] 。如不想限制，则设置为 []
      allowedFileTypes: ['image/*'],

      // 自定义上传参数，例如传递验证的 token 等。参数会被添加到 formData 中，一起上传到服务端。
      // meta: {
      //     token: 'xxx',
      //     otherKey: 'yyy'
      // },
      base64LimitSize: 5 * 1024, // 5kb
      // 将 meta 拼接到 url 参数中，默认 false
      metaWithUrl: false,
      // 自定义增加 http  header
      headers: {
        // otherKey: 'xxx'
        authorization: "Bearer " + localStorage.getItem('token')
      },
      meta: {
        type: 'write',//这是我要传输的，不用可以删掉
      },
      // 跨域是否传递 cookie ，默认为 false
      withCredentials: true,

      // 超时时间，默认为 10 秒
      timeout: 5 * 1000, // 5 秒
      onBeforeUpload(files) {
        return files // 返回哪些文件可以上传
        // return false 会阻止上传
      },
      onProgress(progress) {
        // console.log('onProgress', progress)
      },
      onSuccess(file, res) {
        // console.log('onSuccess', file, res)
      },
      onFailed(file, res) {
        // alert(res.message)
        // console.log('onFailed', file, res)
      },
      onError(file, err, res) {
        // alert(err.message)
        // console.error('onError', file, err, res)
      },


    }
    editorConfig.MENU_CONF['uploadVideo'] = {
      // 上传视频的配置
      
    }
    // 组件销毁时，也及时销毁编辑器
    onBeforeUnmount(() => {
      const editor = editorRef.value
      if (editor == null) return
      editor.destroy()
    })

    const handleCreated = (editor) => {
      editorRef.value = editor // 记录 editor 实例，重要！
    } 
    return {
      editorRef,
      valueHtml,
      mode: 'default', //默认模式
      //   mode: 'simple', //简洁模式
      toolbarConfig,
      editorConfig,
      handleCreated
    };
  }
}
</script> 
