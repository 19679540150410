<template>
    <div>
        <el-card>
            <el-row :gutter="20">
                <el-col :span="2" style="text-align: right;padding-top: 5px;">用户地址：</el-col>
                <el-col :span="6"><el-input v-model="inputAddress" placeholder="请输入要查询的地址" clearable /></el-col>
                <el-col :span="2" style="text-align: right;padding-top: 5px;">查找日期:</el-col>
                <el-col :span="8">
                    <el-date-picker v-model="chooseDate" type="datetimerange" range-separator="-"
                        start-placeholder="开始时间" end-placeholder="结束时间" />
                    </el-col>
                <el-col :span="2"><el-button type="primary" @click="search">查询</el-button></el-col>
            </el-row> 
        </el-card>
        <el-card>
            <el-page-header content="兑换列表" icon="" title="明细管理" />
            <el-table :data="tableData" border style="width: 100%">
                <el-table-column label="序号" align="center" width="150">
                    <template #default="scope">
                        {{ scope.$index + 1 }}
                    </template>
                </el-table-column>
                <el-table-column label="地址" width="400" prop="address" align="center" />
                <el-table-column label="兑换数量MTC(个)" prop="ccamount" align="center" />
                <el-table-column label="兑换数量USDT(个)" prop="usdtamount" align="center" />
                <el-table-column label="兑换类型" align="center">
                    <template #default="scope">
                        {{ scope.row.status == 1 ? "Token->USDT" : "USDT->Token" }}
                    </template>
                </el-table-column>
                <el-table-column label="兑换时间" align="center">
                    <template #default="scope">
                        {{ formatTime.getDateTime(scope.row.createdAt) }}
                    </template>
                </el-table-column>

            </el-table>
        </el-card>
        <div class="pagination-block">
            <el-pagination v-model:current-page="page" v-model:page-size="limit"
                :page-sizes="[10, 20, 50, 100, 200, 300, 400]" :small="small" :disabled="disabled" :background="background"
                layout="total, sizes, prev, pager, next, jumper" :total="total" @size-change="handleSizeChange"
                @current-change="handleCurrentChange" />
        </div>
    </div>
</template>
<script setup  >
import { ref, onMounted } from 'vue'
import axios from 'axios';
import { useRouter } from 'vue-router'
import formatTime from '@/util/formatTime'
const tableData = ref([])
const router = useRouter()
const inputAddress = ref('')
const chooseDate = ref([new Date(new Date().getFullYear, new Date().getMonth, new Date().getDay, 0, 0, 0), new Date(new Date().getFullYear, new Date().getMonth, new Date().getDay, 23, 59, 59)]);
/**分页开始 */
//分页属性
const small = ref(false)
const background = ref(true)
const disabled = ref(false)
const total = ref(400)
const page = ref(1)
const limit = ref(10)  //手动设定
//点击页码查找数据
const handleSizeChange = (val) => {
    limit.value = Number(val)
    getTableData()
}
//跳转分页
const handleCurrentChange = (val) => {
    page.value = Number(val)
    getTableData()
}
const getTableData = async () => {
    const address = inputAddress.value;
    const date = chooseDate.value 
    const res = await axios.post("/adminapi/exchange/list", { page: page.value, limit: limit.value, address, date })
    tableData.value = res.data.data.rows
    total.value = res.data.data.count
}
/**分页结束 */  
const search = async () => { 
    getTableData(); 
}
onMounted(() => {
    getTableData()
})

</script>
<style lang="scss" scoped>
.el-table {
    margin-top: 20px;
}

::v-deep .htmlcontent {
    img {
        max-width: 100%;
    }
}

.titleclass {
    margin-top: 5px;
}

.tag-read {
    cursor: pointer;
}

//分页样式
.pagination-block {
    width: 60%;
    margin: auto;
    margin-top: 20px;
}
</style>