<template>
    <div>
        <el-page-header content="系统参数设置" icon="" title="系统管理" />
        <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
            <el-tab-pane label="移除流动性参数及任务设置" name="first">
                <el-card>
                    <el-form ref="tagFormRef" :model="tagForm" label-width="300px" class="admin-Form">
                        <el-form-item label="移除池子比例：" prop="tag1">
                            <el-input v-model="tagForm.tag1" />
                        </el-form-item>
                        <el-form-item label="直推比例" prop="tag2">
                            <el-input v-model="tagForm.tag2" />
                        </el-form-item>
                        <el-form-item label="间推比例" prop="tag3">
                            <el-input v-model="tagForm.tag3" />
                        </el-form-item>
                        <el-form-item label="Lp一代奖励" prop="tag4">
                            <el-input v-model="tagForm.tag4" />
                        </el-form-item>
                        <el-form-item label="Lp二代奖励" prop="tag5">
                            <el-input v-model="tagForm.tag5" />
                        </el-form-item>                     
                        <el-form-item label="费率" prop="tag8">
                            <el-input v-model="tagForm.tag8" />
                        </el-form-item> 
                        <el-form-item label="池子执行时间" prop="tag6">
                            <el-input v-model="tagForm.tag6" />
                        </el-form-item> 
                        <el-form-item label="启动移除流动性池子开关">
                            <el-button type="primary" @click="startRemoveLp()"
                                :disabled="lpOpenStatus == 0 ? false : true">{{
                                    lpOpenStatus == 0 ? " 启动移除流动性" : "启动中" }}</el-button>
                            <el-button type="primary" @click="canceRemoveLp()" v-if="lpOpenStatus == 1">取消移除流动性</el-button>
                        </el-form-item>
                        
                        <el-form-item>
                            <el-button type="primary" @click="submitForm()">修改</el-button>
                        </el-form-item>
                    </el-form>
                </el-card>
            </el-tab-pane> 
        </el-tabs>
    </div>
</template>
<script setup>
import { ref, reactive, onMounted } from 'vue'
import { ElMessage, TabsPaneContext } from 'element-plus';
import { useRouter } from 'vue-router'
import axios from 'axios';
const router = useRouter()
const activeName = ref('first')
const tagFormRef = ref()
const lpOpenStatus = ref()
 
 
const tagForm = reactive({
    tag1: 0,
    tag2: 0,
    tag3: 0,
    tag4: 0,
    tag5: 0, 
    tag6:'', 
    tag8: 0, 
    
})
const handleClick = (tab, event) => {
    console.log(tab, event)
}

onMounted(() => {
    getTableData()
})
const getTableData = async () => {
    const res = await axios.get("/adminapi/tag/info")
    Object.assign(tagForm, res.data.data)
    //获取流动池任务状态
    const result = await axios.get("/adminapi/tool/getstatus")
    lpOpenStatus.value = result.data.data
    
   
} 



//启动移除lp的任务
const startRemoveLp = async () => {
    await axios.post("/adminapi/tool/start", tagForm).then(res => {
        if (res.data.code == 200) {
            ElMessage.success(res.data.msg)
            lpOpenStatus.value = 1;
        } else {
            ElMessage.error(res.data.msg)
        }
    })
}
//取消移除lp的任务
const canceRemoveLp = async () => {
    await axios.post("/adminapi/tool/cancel", tagForm).then(res => {
        if (res.data.code == 200) {
            ElMessage.success(res.data.msg)
            lpOpenStatus.value = 0;
        } else {
            ElMessage.error(res.data.msg)
        }
    })
}
 
 
//更新提交
const submitForm = () => {
    tagFormRef.value.validate(async (valid) => {
        if (valid) {
            await axios.post("/adminapi/tag/update", tagForm).then(res => {
                if (res.data.code == 200) {
                    ElMessage.success(res.data.msg)
                } else {
                    ElMessage.error(res.data.msg)
                }
            })
        }
    })
}

</script>
<style lang="scss" scoped>
.admin-Form {
    margin-top: 20px;
}
</style>