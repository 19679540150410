<template>
    <div>
        <el-card>
            <el-row :gutter="20">
                <el-col :span="2" style="text-align: right;padding-top: 5px;">用户地址：</el-col>
                <el-col :span="6"><el-input v-model="inputAddress" placeholder="请输入要查询的地址" clearable /></el-col>
                <el-col :span="2">
                    <!-- 会员等级 -->
                    <el-select v-model="grid" placeholder="级别" style="width: 100px">
                        <el-option v-for="item in gridoptions" :key="item.value" :label="item.label" :value="item.value"
                            :disabled="item.disabled" />
                    </el-select>
                </el-col>
                <el-col :span="2" style="text-align: right;padding-top: 5px;">查找日期:</el-col>
                <el-col :span="8">
                    <el-date-picker v-model="chooseDate" type="datetimerange" range-separator="-"
                        start-placeholder="开始时间" end-placeholder="结束时间" />
                    </el-col>
                <el-col :span="2"><el-button type="primary" @click="search">查询</el-button></el-col>
            </el-row> 
        </el-card>
        <el-card>
            <el-page-header content="用户列表" icon="" title="用户管理" />
            <el-table :data="tableData" border style="width: 100%">
                <el-table-column label="序号" align="center">
                    <template #default="scope">
                        {{ scope.$index + 1 }}
                    </template>
                </el-table-column>
                <el-table-column label="地址" width="160" align="center">
                    <template #default="scope">
                        <el-tooltip class="box-item" effect="light" placement="top-start" :content="scope.row.address">
                            <span class="tag-read" :data-clipboard-text="scope.row.address" @click="copyText()"> {{
                                formatHideAddr(scope.row.address) }}</span>
                        </el-tooltip>
                    </template>
                </el-table-column>
                <el-table-column label="昵称" prop="rename" width="100" align="center" />
                <el-table-column label="累积投入金额" prop="amount" width="150" align="center" />
                <el-table-column label="个人算力" prop="power" width="150" align="center" />
                <el-table-column label="账户USDT" prop="usdt" width="150" align="center" />                
                <el-table-column label="个人可提取" prop="idoAmount" width="150" align="center" />
                <!-- <el-table-column label="账户持有旧币数量" prop="nowtoken" width="200" align="center" />  -->
                <!-- <el-table-column label="修改参考数量" align="center" width="200">
                    <template #default="scope">
                        {{ addmoney(scope.row.idoAmount,scope.row.nowtoken) }}
                    </template>
                </el-table-column> -->
                <el-table-column label="工作室Or团队长" align="center" width="130">
                    <template #default="scope">
                        {{ scope.row.grid == 1 ? "工作室" :scope.row.grid == 2 ? "团队长":"————" }}
                    </template>
                </el-table-column>
                <el-table-column label="分配比例" prop="bili" width="90" align="center" />
                <el-table-column label="昨日个人算力挖矿收益" prop="tempUpDayToken" width="170" align="center" />
                <el-table-column label="团队累人数" prop="teamcount" width="150" align="center" />
                <el-table-column label="团队累积业绩(LP)" prop="teamSumMoeny" width="150" align="center" />
                <el-table-column label="团队月新增业绩(LP)" prop="teamNewMoeny" width="160" align="center" />                
                <el-table-column label="是否投入" align="center" width="90">
                    <template #default="scope">
                        {{ scope.row.inputstatus == 1 ? "已投入" : "未投入" }}
                    </template>
                </el-table-column>
                
                <el-table-column label="分享人" width="130" align="center">
                    <template #default="scope">
                        <el-tooltip class="box-item" effect="light" placement="top-start" :content="scope.row.shareAddress">
                            <span class="tag-read" :data-clipboard-text="scope.row.shareAddress" @click="copyText()"> {{
                                formatHideAddr(scope.row.shareAddress) }}</span>
                        </el-tooltip>
                    </template>
                </el-table-column>
                <el-table-column label="创建时间" width="180" align="center">
                    <template #default="scope">
                        {{ formatTime.getDateTime(scope.row.createdAt) }}
                    </template>
                </el-table-column>

                <el-table-column label="操作" align="center">
                    <template #default="scope">
                        <el-button type="warning" :icon="Edit" circle @click="handlePreview(scope.row)" />
                    </template>
                </el-table-column>
            </el-table>
        </el-card>
        <div class="pagination-block">
            <el-pagination v-model:current-page="page" v-model:page-size="limit" :page-sizes="[10, 20, 50, 100, 200, 300, 400]"
                :small="small" :disabled="disabled" :background="background"
                layout="total, sizes, prev, pager, next, jumper" :total="total" @size-change="handleSizeChange"
                @current-change="handleCurrentChange" />
        </div>
        <!--预览对话框-->
        <el-dialog v-model="dialogVisible" title="预览用户信息" width="40%" :before-close="handleClose">

            <div>
                <el-form :model="userForm" label-width="280px">
                    <el-form-item label="钱包地址：">
                        {{ userForm.address }}
                    </el-form-item>
                    <el-form-item label="分享人：">
                        {{ userForm.shareAddress }}
                    </el-form-item>
                    <el-form-item label="昵称：">
                        <el-input v-model="userForm.rename" type="text" style="width: 50%;" placeholder="请输入昵称" />
                    </el-form-item>
                    <el-form-item label="累积投入金额：">
                        <el-input v-model="userForm.amount" type="text" style="width: 50%;" placeholder="请输入投入金额" />
                    </el-form-item>
                    <el-form-item label="当前算力：">
                        <el-input v-model="userForm.power" type="text" style="width: 50%;" placeholder="请输入算力" />
                    </el-form-item>
                    <el-form-item label="个人可提取Token余额：">
                        <el-input v-model="userForm.idoAmount" type="text" style="width: 50%;" placeholder="请输入个人可提取代币数量" />
                    </el-form-item>
                    
                    <el-form-item label="是否投入(1投,2没投)：">
                        <el-input v-model="userForm.inputstatus" type="text" style="width: 50%;" placeholder="请输入1或者2" />
                    </el-form-item>
                    <el-form-item label="是否白名单(1白名单,2黑名单)">
                        <el-input v-model="userForm.blackstatus" type="text" style="width: 50%;"
                            placeholder="请输入1或者2" />
                    </el-form-item>
                    <el-form-item label="设置工作室或团队长(1工作室,2团队长)">
                        <el-input v-model="userForm.grid" type="text" style="width: 50%;"
                            placeholder="请输入1或者2 or0" />
                    </el-form-item>
                    <el-form-item label="分配比例">
                        <el-input v-model="userForm.bili" type="text" style="width: 50%;"
                            placeholder="0.0" />
                    </el-form-item>
                    <el-form-item label="团队累积业绩">
                        {{ userForm.teamSumMoeny }}
                    </el-form-item>
                    <el-form-item label="团队新增业绩">
                        {{ userForm.teamNewMoeny }}
                    </el-form-item>
                    <el-form-item label="创建时间：">
                        {{ formatTime.getDateTime(userForm.createdAt) }}
                    </el-form-item>
                    <el-form-item label="更新时间：">
                        {{ formatTime.getDateTime(userForm.updatedAt) }}
                    </el-form-item>
                </el-form>
                <div style="text-align: center;width: 100%;"> <el-button type="primary"
                        @click="handleAddTime()">修改</el-button></div>
            </div>
        </el-dialog>

    </div>
</template>
<script setup>
import { ref, onMounted, reactive } from 'vue'
import { ElMessage } from 'element-plus';
import { Edit } from '@element-plus/icons-vue'
import Clipboard from 'clipboard'
import axios from 'axios';
import { useRouter } from 'vue-router'
import formatTime from '@/util/formatTime'
const tableData = ref([])
const dialogVisible = ref(false)
const userForm = reactive({})
const router = useRouter()
const inputAddress = ref('')
const chooseDate = ref([new Date(new Date().getFullYear, new Date().getMonth, new Date().getDay, 0, 0, 0), new Date(new Date().getFullYear, new Date().getMonth, new Date().getDay, 23, 59, 59)]);
const grid = ref('0')
const gridoptions = [
    {
        value: '0',
        label: '全部',
    },
     
    {
        value: '1',
        label: '工作室',
    },
    {
        value: '2',
        label: '团队长',
    }  
]
/**分页开始 */
//分页属性
const small = ref(false)
const background = ref(true)
const disabled = ref(false)
const total = ref(400)
const page = ref(1)
const limit = ref(10)  //手动设定
//点击页码查找数据
const handleSizeChange = (val) => {
    limit.value = Number(val)
    getTableData()
}
const addmoney= (a,b)=>{
    return   parseFloat(a)+parseFloat(b)
}
//跳转分页
const handleCurrentChange = (val) => {
    page.value = Number(val)
    getTableData()
}
const getTableData = async () => {
    const address = inputAddress.value;
    const date = chooseDate.value 
    const res = await axios.post("/adminapi/user/list", { page: page.value, limit: limit.value ,address,date,grid:grid.value,})
    tableData.value = res.data.data.rows
    total.value = res.data.data.count
}
/**分页结束 */
const search = async () => { 
    getTableData(); 
}
onMounted(() => {
    getTableData()
})

const handleAddTime = async () => {
    console.log(userForm)
    const id = userForm.id;
    const a = userForm.rename;
    const b = userForm.amount;
    const c = userForm.power;
    const d = userForm.idoAmount;
    const e = userForm.inputstatus;
    const f = userForm.blackstatus;
    const g = userForm.grid;
    const h = userForm.bili;
    await axios.post("/adminapi/user/rechargev", { id, a, b, c, d,e,f ,g,h})
    dialogVisible.value = false
    getTableData()
    ElMessage.success('更新成功')
}
const copyText = () => {
    var clipboard = new Clipboard('.tag-read')
    clipboard.on('success', e => {
        ElMessage.success('复制成功')
        //  释放内存
        clipboard.destroy()
    })
    clipboard.on('error', e => {
        ElMessage.error('该浏览器不支持复制')
        // 不支持复制

        // 释放内存
        clipboard.destroy()
    })
}
const handlTransfer=async(address)=>{
    console.log(address)
   const res= await axios.post("/adminapi/user/transfer", { address})
   ElMessage.success(res.data.msg)
}
//预览
const handlePreview = async (data) => {
    console.log(data)
    // previewData.value = data  
    dialogVisible.value = true
    Object.assign(userForm, data)
}
 
const formatHideAddr = (v) => {
    if (v != null && v != "") {
        return v.substring(0, 4) + "****" + v.substr(v.length - 4);
    }
    return ""
}

</script>
<style lang="scss" scoped>
.el-table {
    margin-top: 20px;
}

::v-deep .htmlcontent {
    img {
        max-width: 100%;
    }
}

.titleclass {
    margin-top: 5px;
}

.tag-read {
    cursor: pointer;
}

//分页样式
.pagination-block {
    width: 60%;
    margin: auto;
    margin-top: 20px;

}
</style>