<template>
    <el-aside :width="$store.state.isCollapsed ? '64px' : '200px'">
        <el-menu :collapse="$store.state.isCollapsed" :collapse-transition="false" :router="true"
            :default-active="route.fullPath">
            <el-menu-item index="/index">
                <el-icon><home-filled /></el-icon>
                <span>首页</span>
            </el-menu-item>
            <el-menu-item index="/center">
                <el-icon>
                    <Avatar />
                </el-icon>
                <span>个人中心</span>
            </el-menu-item>
            <el-sub-menu index="/system-manage" v-admin>
                <template #title>
                    <el-icon>
                        <Setting />
                    </el-icon>
                    <span>系统管理</span>
                </template>
                <el-menu-item index="/system-manage/addsystem">添加管理员</el-menu-item>
                <el-menu-item index="/system-manage/systemlist">管理员列表</el-menu-item>
                <el-menu-item index="/system-manage/systemset">参数设置</el-menu-item>
            </el-sub-menu>

            <el-sub-menu index="/user-manage">
                <template #title>
                    <el-icon>
                        <User />
                    </el-icon>
                    <span>用户管理</span>
                </template>
                <el-menu-item index="/user-manage/userlist">用户列表</el-menu-item>
            </el-sub-menu>
            <el-sub-menu index="/list-manage">
                <template #title>
                    <el-icon>
                        <List />
                    </el-icon>
                    <span>明细管理</span>
                </template>
                <el-menu-item index="/list-manage/inmoneylist">投入记录</el-menu-item> 
                <el-menu-item index="/list-manage/rewardlist">奖励明细</el-menu-item>
                <el-menu-item index="/list-manage/powerlist">算力明细</el-menu-item> 
                <el-menu-item index="/list-manage/extractlist">提取明细</el-menu-item>
                <el-menu-item index="/list-manage/outusdtlist">USDT提取明细</el-menu-item> 
                <el-menu-item index="/list-manage/exchangelist">兑换明细</el-menu-item> 
                <el-menu-item index="/list-manage/removelplist">移除明细</el-menu-item> 
            </el-sub-menu> 
            <el-sub-menu index="/set-manage" >
                <template #title>
                    <el-icon>
                        <Platform />
                    </el-icon>
                    <span>项目设置</span>
                </template>
                <el-menu-item index="/set-manage/introductionset">项目介绍</el-menu-item>
                <el-menu-item index="/set-manage/rouleset">项目规则</el-menu-item>
            </el-sub-menu>
            <el-sub-menu index="/notice-manage" >
                <template #title>
                    <el-icon>
                        <Message />
                    </el-icon>
                    <span>公告管理</span>
                </template>
                <el-menu-item index="/notice-manage/addnotice">添加公告</el-menu-item>
                <el-menu-item index="/notice-manage/noticelist">公告列表</el-menu-item>
            </el-sub-menu> 
            <el-sub-menu index="/log-manage" v-admin>
                <template #title>
                    <el-icon>
                        <Notebook />
                    </el-icon>
                    <span>操作日志管理</span>
                </template>
                <el-menu-item index="/log-manage/loglist">日志列表</el-menu-item> 
            </el-sub-menu>
           
        </el-menu>
    </el-aside>
</template>
<script setup>
import { HomeFilled, Avatar, Message,Coordinate,Setting ,User,Present,Notebook,List,Platform} from '@element-plus/icons-vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
const store = useStore()
const route = useRoute()
const vAdmin = {
    mounted(el) {
        if (store.state.userInfo.role !== 1) {
            el.parentNode.removeChild(el)
        }
    }
}
</script>
<style lang="scss" scoped>
.el-aside {
    height: 100vh;

    .el-menu {
        height: 100vh;
    }
}
</style>