<template>
    <div>
        <el-card>
            <el-page-header content="公告列表" icon="" title="公告管理" />
            <el-table :data="tableData" border  style="width: 100%">
                <el-table-column label="序号"  align="center">
                    <template #default="scope">
                        {{ scope.$index + 1 }}
                    </template>
                </el-table-column>
                <el-table-column prop="title" label="标题" width="300" align="center"/>

                <el-table-column label="是否发布" align="center">
                    <template #default="scope">
                        <el-switch v-model="scope.row.isPublish" :active-value="1" :inactive-value="2"
                            @change="handleSwitchChange(scope.row)" />
                    </template>
                </el-table-column>

                <el-table-column label="创建时间" align="center">
                    <template #default="scope">
                        {{ formatTime.getTime(scope.row.createdAt) }}
                    </template>
                </el-table-column>
                <el-table-column label="更新日期" align="center">
                    <template #default="scope">
                        {{ formatTime.getTime(scope.row.updatedAt) }}
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="180" align="center">
                    <template #default="scope">
                        <el-button type="warning" :icon="Star" circle @click="handlePreview(scope.row)" />
                        <el-button type="primary" :icon="Edit" circle @click="handleEdit(scope.row)"></el-button>
                        <el-popconfirm title="您确认要删除此条数据吗?" confirm-button-text="确定" cancel-button-text="取消"
                            @confirm="handleDelete(scope.row)">
                            <template #reference>
                                <el-button :icon="Delete" circle></el-button>
                            </template>
                        </el-popconfirm>
                    </template>
                </el-table-column>
            </el-table>
        </el-card>
        <!--预览对话框-->
        <el-dialog v-model="dialogVisible" title="预览公告" width="50%" :before-close="handleClose">
            <div>
                <h2>{{ previewData.title }}</h2>
                <div class="titleclass">{{ formatTime.getTime(previewData.updatedAt) }}</div>
                <el-divider>
                    <el-icon>
                        <star-filled />
                    </el-icon>
                </el-divider>
                <div v-html="previewData.content" class="htmlcontent">
                </div>
            </div>
        </el-dialog>

    </div>
</template>
<script setup>
import { ref, onMounted, reactive } from 'vue'
import { ElMessage } from 'element-plus';
import { Delete, Edit, Star, StarFilled } from '@element-plus/icons-vue'
import axios from 'axios';
import { useRouter } from 'vue-router'
import formatTime from '@/util/formatTime'
const tableData = ref([])
const dialogVisible = ref(false)
const previewData = ref()

const router = useRouter()
onMounted(() => {
    getTableData()
})
const getTableData = async () => {
    const res = await axios.get("/adminapi/notice/list")
    tableData.value = res.data.data
}
//编辑
const handleEdit = async (item) => {
    //跳转到编辑页面 
    router.push(`/notice-manage/editnotice/${item.id}`)

}
//预览
const handlePreview = async (data) => {
    previewData.value = data
    dialogVisible.value = true
}
//删除
const handleDelete = async (data) => {
    await axios.delete(`/adminapi/notice/list/${data.id}`)
    ElMessage.success('删除成功')
    getTableData()
}
//发布or不发布
const handleSwitchChange = async (item) => {
    await axios.put(`/adminapi/notice/publish`, { id: item.id, isPublish: item.isPublish })
    ElMessage.success('修改成功')
    getTableData()
}

//修改数据
const handleEditConfirm = async () => {
    adminFormRef.value.validate(async (valid) => {
        if (valid) {
            await axios.put(`/adminapi/notice/list/${adminForm.id}`, adminForm).then(res => {
                if (res.data.code == 200) {
                    dialogVisible.value = false
                    router.push(`/system-manage/systemlist`)
                    ElMessage.success(res.data.msg)
                    getTableData()

                } else {
                    ElMessage.error(res.data.msg)
                }
            })
        }
    })
}

</script>
<style lang="scss" scoped>
.el-table {
    margin-top: 50px;
}

::v-deep .htmlcontent {
    img {
        max-width: 100%;
    }
}

.titleclass {
    margin-top: 5px;
}
</style>