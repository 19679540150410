<template>
    <div>
        <el-page-header content="个人中心" icon="" title="WEB3抢单系统" />
        <el-row :gutter="20" class="el-row">
            <el-col :span="8">
                <el-card class="box-card">
                    <el-avatar :size="200" :src="avatarUrl" />
                    <h3>{{ store.state.userInfo.username }}</h3>
                    <h3>{{ store.state.userInfo.role == 1 ? '管理员' : '编辑' }}</h3>
                </el-card>
            </el-col>
            <el-col :span="16">
                <el-card>
                    <template #header>
                        <div class="card-header">
                            <span>个人信息</span>
                        </div>
                    </template>
                    <el-form ref="adminFormRef" :model="adminForm" :rules="adminFormRules" label-width="120px"
                        class="demo-ruleForm">
                        <el-form-item label="用户名" prop="username">
                            <el-input v-model="adminForm.username" />
                        </el-form-item>
                        <!-- <el-form-item label="状态" prop="state">

                            <el-radio-group v-model="adminForm.state">
                                <el-radio :label="1">开启</el-radio>
                                <el-radio :label="2">禁用</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="角色" prop="role">
                            <el-radio-group v-model="adminForm.role">
                                <el-radio :label="1">管理员</el-radio>
                                <el-radio :label="2">编辑</el-radio>
                            </el-radio-group>
                        </el-form-item> -->
                        <el-form-item label="头像" prop="avatar">
                            <Upload :avatar="adminForm.avatar" @change="handleChange" />
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="submitForm()">更新</el-button>
                        </el-form-item>
                    </el-form>
                </el-card>
            </el-col>
        </el-row>
    </div>
</template>
<script setup>
import { useStore } from 'vuex';
import { computed, ref, reactive } from 'vue'
import { ElMessage } from 'element-plus';
import upload from '@/util/upload'
import Upload from '@/components/upload/Upload'
const store = useStore()
const avatarUrl = computed(() => store.state.userInfo.avatar ? store.state.baseUrl + store.state.userInfo.avatar : `https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png`)
const { username, avatar, state, role } = store.state.userInfo
const adminFormRef = ref()
const adminForm = reactive({
    username: username,
    state,
    avatar:0,
    role:0,
    file: null
})
const adminFormRules = reactive({
    username: [
        {
            required: true, message: "请输入用户名", trigger: "blur"
        }
    ], state: [
        {
            required: true, message: "请选择状态", trigger: "blur"
        }
    ], role: [
        {
            required: true, message: "请选择角色", trigger: "blur"
        }
    ], avatar: [
        {
            required: true, message: "请上传头像", trigger: "blur"
        }
    ]
})
//每次选择完图片的回调
const handleChange = (file) => {
    adminForm.avatar = URL.createObjectURL(file)
    adminForm.file = file
}
//更新提交
const submitForm = () => {
    adminFormRef.value.validate(async (valid) => {
        if (valid) {
            await upload("/adminapi/admin/updateadmin", adminForm).then(res => {
                if (res.data.code == 200) {
                    store.commit("changeUserInfo", res.data.data)
                    ElMessage.success(res.data.msg)
                } else {
                    ElMessage.error(res.data.msg)
                }
            })
        }
    })
}
</script>
<style lang="scss" scoped >
.el-row {
    margin-top: 20px;

    .box-card {
        text-align: center;
    }
}
</style>